import React from 'react'
import FlagBR from '../Assets/Imagens/flag_br.png'
import FlagUSA from '../Assets/Imagens/flagUSA.png'
export default function TopBar({ changeLanguague, language }) {
    return (<>
        <div id='TopBar'>
            <div className="TopBar">
                <p className="TopBar-Link">Tardioli Assesoria</p>
                <div className="TopBar-Links">
                    <p className="TopBar-LinkT1">{language === 'pt-br' ? 'Serviços' : 'Services'}</p>
                    <p className="TopBar-LinkT1">{language === 'pt-br' ? 'Contatos' : 'Contacts'}</p>
                </div>
            </div>
            <div className="TopBarIdioma" onClick={changeLanguague}>

                {language === 'pt-br' ? (<p className='TextIdioma' > idioma <strong>(pt-br)</strong> <img src={FlagBR} className='Flags' alt='Flag' /> <span className='ArrowDown' />         </p>) : (
                    <p className='TextIdioma' >language <strong>(en-us)</strong> <img src={FlagUSA} className='Flags' alt='Flag' /> <span className='ArrowDown' /></p>)}


            </div>
            <span className="TopBarSombra" />
        </div>
    </>)
}