import React from 'react'
import LogoSemFundo from '../Assets/Imagens/Logo_sem_fundo.png'
import Listra from '../Assets/Imagens/Listra.png'
const StartBody = ({language}) =>{

    return (
        <>
        <div id='StartBody'>
            <span className='LogoBackgroundDisfocado'/>
                <img src={LogoSemFundo} className='StartBodyLogo' alt='Logo Sem Fundo'/>

                <img src={Listra} className='ListaLeft' alt='Listra'/>
                <img src={Listra} className='ListaRight' alt='Listra'/>
        </div>
        </>
    )
}

export default StartBody;