import React, { useState } from 'react'
import TopBar from './TopBar'
import FeedInsta from '../InstaFeed/Insta'
import InfoTardioli from './Info'
import DireitoItalia from './DireitoItalia'
import InfoProcesso from './InfoProcesso'
import StartBody from './StartBody'
import Sendmail from '../components/Sendmail'
export default function DashBoard() {
    const [language, setlanguage ] = useState('pt-br');
    const changeLanguague = () => {
        console.log('asd')
        {language === 'pt-br'? setlanguage('en-us')  : setlanguage('pt-br') }
    }
    return (

        <>
                <TopBar changeLanguague={() => changeLanguague()} language={language} />
                <Sendmail />
                <StartBody language={language}/>
                <InfoProcesso language={language}/>
                {/* <FeedInsta /> */}
                {/* 
                <InfoProcesso />
                <img className='ListraBody1' src={Listra} alt="Listras" />
                <DireitoItalia />
                <img className='ListraBody2' src={Listra2} alt="Listras" />
                <FeedInsta /> */}



                {/* <clippath>
                    {/* ClipPath SVG 
                    <svg className="ClipPath"><defs><clipPath id="InfoButtonOne"><path d="M0,33.21108515769584L560.2288161794277,0L1120.4576323588553,33.21108515769584L1120.4576323588553,222.7889148423042L560.2288161794277,256L0,222.7889148423042L0,33.21108515769584Z"></path></clipPath></defs></svg>
                    <svg className="ClipPath"><defs><clipPath id="InfoButtonTwo"><path d="M0,33.48249784168503L194.2089353402983,0L388.4178706805966,33.48249784168503L388.4178706805966,222.51750215831495L194.2089353402983,256L0,222.51750215831495L0,33.48249784168503Z"></path></clipPath></defs></svg>
                    <svg className="ClipPath"><defs><clipPath id="InfoButtonThree"><path d="M0,33.211085157695805L350.1655702551315,0L700.331140510263,33.211085157695805L700.331140510263,222.7889148423042L350.1655702551315,256L0,222.7889148423042L0,33.211085157695805Z"></path></clipPath></defs></svg>
                    <svg className="ClipPath"><defs><clipPath id="InfoButtonFour"><path d="M0,32.20860726769834L437.4565222074994,0L874.9130444149988,32.20860726769834L874.9130444149988,223.79139273230166L437.4565222074994,256L0,223.79139273230166L0,32.20860726769834Z"></path></clipPath></defs></svg>
                </clippath> */}
        </>
    )
}
