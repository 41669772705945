import React from 'react';
import { createRoot } from 'react-dom/client';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import './index.css';
import './App.css';
import Page from './App'
import reportWebVitals from './reportWebVitals';
const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Router>
        <Routes>
        <Route path="/" element={<Page/>}/>
        </Routes>
      </Router>
  </React.StrictMode>
);


reportWebVitals();
