import React, { useState } from 'react'
import Axios from 'axios'
import Instagram from '../Assets/Imagens/Insta.png'

const initialFeedItemState = {
    id: '',
    media_type: "IMAGE" | "VIDEO",
    media_url: '',
    permalink: '',
    thumbnail_url: '',
    username: '',
    children: '',
    timestamp: '',
};
async function GerarFeed() {
    const token = process.env.REACT_APP_INSTA_TOKEN;
    const campos = "media_url,media_type,permalink,thumbnail_url,id,username,children,timestamp";
    const maxLimite = 2;
    const Req = `https://graph.instagram.com/me/media?access_token=${token}&fields=${campos}&limit=${maxLimite}`;
    return await Axios.get(Req);
}
function FeedInsta() {
    const [feedList, setFeedList] = useState([initialFeedItemState]);
    const [executado, setExecutado] = useState(false)
    const SetFeed = async e => {
        if (executado) { return null }
        const { data } = await GerarFeed();
        setFeedList(data.data);
        setExecutado(true);
    }
    SetFeed()

    const NewPage = (link) => {
        window.open(link, '_blank')
        return false;
    }


    return (
        <>
            <span className='InstaFeedsText1'>Acompanhe nas redes sociais</span>
            <div className='InstaFeeds'>
                {feedList !== undefined && feedList !== '' && feedList.map((item) => {
                    if (item.media_type === 'VIDEO') {
                        return <div key={item.id} onClick={() => NewPage(item.permalink)} style={{ cursor: 'pointer' }} className='InstaFeedsPost'>
                            <img className='InstaFeedsPostImage' style={{ width: '300px', height: "300px" }} src={item.thumbnail_url} alt={item.id}></img>
                            <div className='InstaFeedsPostDivLogo'>
                                <img alt='Instagram' src={Instagram} className='InstaFeedsPostDivLogoI' />
                            </div>
                        </div>
                    } else {
                        return <div key={item.id} onClick={() => NewPage(item.permalink)} style={{ cursor: 'pointer' }} className='InstaFeedsPost'>
                            <img className='InstaFeedsPostImage' style={{ width: '300px', height: "300px" }} src={item.media_url} alt={item.id}></img>
                            <div className='InstaFeedsPostDivLogo'>
                                <img alt='Instagram' src={Instagram} className='InstaFeedsPostDivLogoI' />
                            </div>
                        </div>
                    }

                })}
            </div>
        </>
    )
}

export default FeedInsta;