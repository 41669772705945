import './App.css';
import React from 'react'
import DeshBoard from './pages/DeshBoard'
function App() {
    
  return (
    <>
      <DeshBoard/>

      </>
  );
}

export default App;
