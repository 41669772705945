import emailjs from '@emailjs/browser'
import React, { useState } from 'react';

const SendMail = () => {
    const [listAssunto, setListAssunto] = useState(false)
    const [contatoSelect, setContatoSelect] = useState('WhatsApp')
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        numberWhats: '',
        formContatc: 'WhatsApp',
        message: '',
        typeAssunto: '',
    });

    const [assuntoSelect, setAssuntoSelect] = useState('Escolha 1 Assunto')
    const setAssunto = (assunto) => {
        setAssuntoSelect(assunto)
        setListAssunto(false)
        setFormData(prevState => ({
            ...prevState,
            typeAssunto: assunto,
        }))
    }
    const setContact = (contatc) => {
        setContatoSelect(contatc)
        setFormData(prevState => ({
            ...prevState,
            formContatc: contatc,
        }))
    }
    const handleSubmit = () => {
        const { fullName, email, numberWhats, message, typeAssunto, formContatc } = formData;

        if (!fullName || !email || !message || !numberWhats) {
            console.error('Por favor, preencha todos os campos obrigatórios.');
            return;
        }

        console.log(formData)

        emailjs.send('serviceSendMailForGmail', 'TemplateIDTabataAssesori', formData, {
            publicKey: 'sJDwZkE44pyKbDfmo',
        })
            .then(() => {
                console.log('Email enviado com sucesso!');
                setFormData({
                    fullName: '',
                    email: '',
                    numberWhats: '',
                    formContatc: 'WhatsApp',
                    message: '',
                    typeAssunto: '',
                });
                
            })
            .catch((error) => {
                console.error('Falha ao enviar o email:', error);
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
        console.log(formData)
    };

    return (
        <div id='BodySendMail'>
                <p className='BodySendMailText'>Preencha abaixo para atendimento personalizado e/ou duvidas.</p>
                <div className='BodySendMail'>
                    <div className='SendMailInputs'>
                        <span>Nome Completo :</span>
                        <input type='text' placeholder='Nome Completo' value={formData.fullName} name='fullName' required onChange={handleChange} />
                    </div>
                    <div className='SendMailInputs' style={{ marginTop: '10px' }}>
                        <span style={{ marginLeft: '40px' }}>Email :</span>
                        <input type='email' placeholder='Email@email.com' value={formData.email} name='email' required onChange={handleChange} />
                    </div>
                    <div className='SendMailInputs' style={{ marginTop: '10px' }}>
                        <span style={{ marginLeft: '30px' }}>Número :</span>
                        <input type='tel' placeholder='(xx) xx xxxx-xxxx' value={formData.numberWhats} name='numberWhats' required onChange={handleChange} />
                    </div>
                    <div className='SendMailInputs' style={{ marginTop: '10px' }}>
                        <span style={{ marginLeft: '30px' }}>Assunto :</span>
                        <span className='SendMailInputSelect' onClick={() => listAssunto ? setListAssunto(false) : setListAssunto(true)} >{assuntoSelect}</span>
                        {listAssunto &&
                            <div className='SendoMialInputList'>
                                <span className='SendMailInputSelect' onClick={() => setAssunto('Opção 1')} id='SendInputQCP3C2'>Opção 1</span>
                                <span className='SendMailInputSelect' onClick={() => setAssunto('Opção 2')} id='SendInputQCP3C2'>Opção 2</span>
                                <span className='SendMailInputSelect' onClick={() => setAssunto('Opção 3')} id='SendInputQCP3C2'>Opção 3</span>
                            </div>}
                    </div>


                    <span className='HRWHITE' />
                    <div className='SendMailContacts'>
                        <p>Por onde deseja ser contatado?</p>
                        <div className='SendMaisSelectContacts'>
                            <p style={{ cursor: 'pointer' }} onClick={() => setContact('WhatsApp')}><span className={contatoSelect ==='WhatsApp' ? 'SendMailConectacsBallS' : 'SendMailConectacsBall'}></span>WhatsApp</p>
                            <p style={{ cursor: 'pointer' }} onClick={() => setContact('Email')}><span className={contatoSelect === 'Email' ? 'SendMailConectacsBallS' : 'SendMailConectacsBall'}></span>Email</p>
                        </div>
                    </div>
                    <div id='SendMailMensage'>
                        <p>Mensagem:</p>
                        <textarea value={formData.message} name='message' required onChange={handleChange} placeholder='Digite sua mensagem' />
                    </div>
                    <div className='SendInputSubmit'>
                        <button onClick={handleSubmit}>Enviar</button>
                        <p>Atendimento e reuniões online!!</p>
                    </div>
                </div>
        </div>
    );
}

export default SendMail;