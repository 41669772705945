import React, { useEffect, useState } from "react";
import ItaliaLogo from '../Assets/Imagens/ItaliaLogo.png'
import Reconhecimento from '../Assets/Imagens/ReconhecimentoI.png'
const InfoProcesso = ({ language }) => {
    const [textIdioma, setTextIdioma] = useState({
        ptBR: [`Prazer, Sou a Tabata- especialista em cidadania italiana!<br/>
        Minha Missão é ajudar você que sonha em morar na Europa e não sabe como.<br/>
        Me tornei especialista no assunto fazendo o meu processo e também ajudando amigos, hoje ajudo você a realizar o sonho de ter a cidadania europeia tão sonhada, poder morar e trabalhar fora sem se preocupar com a ilegalidade.
        Descubra se seus antepassados deixaram uma herança valiosa para você, a dupla cidadania. Com minha orientação especializada, você  Descobrirá se possui ou não direito a cidadania italiana ou portuguesa, abra as portas na Europa com seu passaporte europeu.
        Agora se você já tem a cidadania e busca por serviços como AIRE, agendamento de Passaporte, ESTA ou ETA, também esta no lugar certo.<br /><br />

        Entre em contato conosco, vamos tirar suas duvidas e conversar!!`,
            'Reconhecimento de Cidadania Italiana',
            'É feita a busca da sua certidão para o reconhecimento de tais cidadanias',
            'Agendamento de passaporte italiano',
            'Em qualquer consulado do mundo!  É um cidadão italiano em outro país? Faço seu agendamento no consulado de sua região.',
            'Inscrição de Aire Italiano',
            'Você cidadão italiano que não reside na Itália, sabia que precisa da atualização de Aire sempre que alterar qualquer dado?',
        ],

        enUS: [`Nice to meet you, I'm Tabata - specialist in Italian citizenship!<br />
        My mission is to help you who dream of living in Europe and don't know how.<br />
        I became an expert on the subject by going through my own process and also helping friends; Today, I help you realize your dream of obtaining European citizenship, being able to live and work abroad without worrying about legality.
        Find out if your ancestors left you a valuable inheritance, dual citizenship. With my expert guidance you will discover whether you are entitled to Italian or Portuguese citizenship, opening doors in Europe with your European passport.
        Now, if you already have citizenship and are looking for services such as AIRE, passport scheduling, ESTA or ETA, you are also in the right place.<br /><br />
        
        Contact us, let's answer your questions and have a chat!`,
            'Recognition of Italian Citizenship',
            'Your certificate will be searched for recognition of such citizenships',
            'Italian Passport Appointment',
            'Anywhere in the world! Are you an Italian citizen in another country? I can schedule your appointment at the consulate in your region.',
            'Italian AIRE Registration',
            'Italian citizens residing outside of Italy, did you know that you need to update your AIRE registration whenever you change any information?'
        ]

    })
    useEffect(() => {
        document.querySelector('.TextProcessT2').innerHTML = language === 'pt-br' ? textIdioma.ptBR[0] : textIdioma.enUS[0]
    }, [language])
    return (<>
        <span className="ListraInfoProcesso" />
        <div id="BodyInfoProcesso">

            <div className="BodyProcessoPart1">
                <div className="BodyProcessoTextArea">
                    <p className="TextProcessT1">Tardioli assessoria</p>
                    <hr className="BarrerProcesso" />
                    <span className="TextProcessT2">
                    </span>

                    <img src={ItaliaLogo} alt='Italia' className="ItaliaLogoProcesso" />
                </div>
            </div>


            <div className="ListProcesso">

                <div className="BlocoProcesso">
                    <span className="BackgroundBlocoProcesso" />
                    <span className="BlocoProcessoImage" id="BlocoProcessoImage1" />
                    <p className="BlocoProcessoText1">{language === 'pt-br' ? textIdioma.ptBR[1] : textIdioma.enUS[1]}</p>
                    <p className="BlocoProcessoText2">{language === 'pt-br' ? textIdioma.ptBR[2] : textIdioma.enUS[2]}</p>
                </div>


                <div className="BlocoProcesso">
                    <span className="BackgroundBlocoProcesso" />
                    <span className="BlocoProcessoImage" id="BlocoProcessoImage2" />
                    <p className="BlocoProcessoText1">{language === 'pt-br' ? textIdioma.ptBR[3] : textIdioma.enUS[3]}</p>
                    <p className="BlocoProcessoText2">{language === 'pt-br' ? textIdioma.ptBR[4] : textIdioma.enUS[4]}</p>

                </div>


                <div className="BlocoProcesso">
                    <span className="BackgroundBlocoProcesso" />
                    <span className="BlocoProcessoImage" id="BlocoProcessoImage3" />
                    <p className="BlocoProcessoText1">{language === 'pt-br' ? textIdioma.ptBR[5] : textIdioma.enUS[5]}</p>
                    <p className="BlocoProcessoText2">{language === 'pt-br' ? textIdioma.ptBR[6] : textIdioma.enUS[6]}</p>

                </div>

            </div>
            <span className="BlocoEtapasProcesso">Veja as etapas do procedimento</span>
        </div>
    </>
    )
}

export default InfoProcesso;